import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Events from 'src/components/events/events';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const EventsPage = () => {
  usePrivateRoute();
  return (
    <Router basepath="/tournaments">
      <EventsRoute path="/:tournamentId" />
    </Router>
  );
};

interface EventsRouteProps extends RouteComponentProps {
  tournamentId?: string;
}

const EventsRoute: React.FC<EventsRouteProps> = ({ tournamentId }) => {
  return (
    <Layout>
      <SEO title="Events" />
      <Events tournamentId={tournamentId} />
    </Layout>
  );
};

export default EventsPage;
