import React, { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { tournamentsClient } from 'src/apollo/client';
import AdminTable from 'src/components/admin-table/admin-table';
import { getClientConfig } from 'src/config/config';
import { GetEvents, GetEventsVariables } from 'src/graphql-types/GetEvents';
import { useOrgHierarchy } from 'src/utils/auth';

import { GetOrganisationMembership, getOrganizationMembership } from '../../utils/classic-api';
import APIErrorMessage from '../api-error-message/api-error-message';
import MembershipErrorComponent from '../membership-error-component/membership-error-component';
import PageHeader from '../page-header/page-header';
import Panel, { PanelGroup } from '../panel/panel';
import Spinner from '../spinner/spinner';
import TournamentInfo from '../tournament-info/tournament-info';
import { PageMaxWidth } from '../util-components/util-components';
import { GET_EVENTS } from './events-queries';
import EventsTable from './events-table/events-table';
import * as styles from './events.module.less';
import TournamentConfiguration from './tournament-configuration/tournament-configuration';
import TournamentTags from './tournament-tags/tournament-tags';

interface Props {
  tournamentId?: string;
}

const Events: React.FC<Props> = ({ tournamentId }) => {
  const [organisationMembership, setOrganisationMembership] = useState<GetOrganisationMembership>();
  const [loadingOrganisation, setLoadingOrganisation] = useState<boolean>(true);
  const { loading, data, error } = useQuery<GetEvents, GetEventsVariables>(GET_EVENTS, {
    client: tournamentsClient,
    variables: { id: tournamentId },
  });
  const tourn = data?.tournament;
  const { t } = useTranslation();
  const { tournamentOrgMembership: showOrgMembership } = getClientConfig();

  useEffect(() => {
    if (data?.tournament?.organisation?.id && showOrgMembership) {
      const getMembership = async () => {
        const response = await getOrganizationMembership(data?.tournament?.organisation?.id as string);
        setOrganisationMembership(response as any);
        setLoadingOrganisation(false);
      };

      getMembership().catch((err) => console.log(err));
    }
  }, [data, showOrgMembership]);

  const orgHierarchy = useOrgHierarchy();
  const hideTags = orgHierarchy?.length !== 1;

  const expiryDate = useMemo(() => {
    const date = moment(organisationMembership?.MembershipExpiryDate).local();
    return `${t('membership expiry date', { date })}`;
  }, [organisationMembership, t]);

  const isOrgActive = useMemo(() => {
    return organisationMembership?.MembershipStatus === 'Active' && organisationMembership?.OrgStatus === 'ACTIVE';
  }, [organisationMembership]);

  return (
    <PageMaxWidth>
      <PanelGroup>
        <PageHeader title={t('tournament info')} />
        {showOrgMembership && (
          <MembershipErrorComponent
            loadingOrganisation={loadingOrganisation}
            organisationMembership={organisationMembership}
          />
        )}
        {error && <APIErrorMessage error={t('tournament permission error')} />}
        {!error && (
          <>
            <TournamentInfo tournament={tourn} loading={loading} />
            <EventsTable tournamentId={tournamentId} data={data} loading={loading} />
            {loading ? (
              <Spinner />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Panel title={t('tournament staff')} spacing={{}}>
                    <SizedGridContent>
                      <AdminTable
                        columns={[
                          {
                            key: 'director',
                            getValue: (d) => <span className={styles.directorName}>{d.name}</span>,
                          },
                          { key: 'role', getValue: (d) => d.role },
                        ]}
                        data={[
                          {
                            id: tourn?.director?.id,
                            name: tourn?.director ? `${tourn.director.firstName} ${tourn.director.lastName}` : t('n/a'),
                            role: t('tournament director'),
                          },
                        ]}
                      />
                    </SizedGridContent>
                  </Panel>
                </Grid>
                {!hideTags && tourn && <TournamentTags tournamentId={tournamentId ?? ''} tags={tourn?.tags ?? []} />}

                {tourn && <TournamentConfiguration tournamentId={tourn.id} features={tourn.featureSettings} />}
                {organisationMembership && (
                  <Grid item xs={12} md={6} className={styles.sizedGrid}>
                    <Panel title={t('organization information')} spacing={{}}>
                      <SizedGridContent>
                        <div>{organisationMembership?.Name}</div>
                        <div className={styles.organizationInfo}>
                          <div>
                            <h4>{t('membership status')}</h4>
                            <p>{isOrgActive ? t('active') : t('inactive')}</p>
                          </div>
                          <div>
                            <h4>{t('membership expiry')}</h4>
                            <p>{expiryDate}</p>
                          </div>
                          <div>
                            <h4>{t('membership contact')}</h4>
                            <p>{organisationMembership?.ContactName}</p>
                          </div>
                          <div>
                            <h4>{t('organization type')}</h4>
                            <p>{organisationMembership?.OrgType?.Name}</p>
                          </div>
                          <div>
                            <h4>{t('organization uaid')}</h4>
                            <p>{organisationMembership?.UAID}</p>
                          </div>
                        </div>
                      </SizedGridContent>
                    </Panel>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </PanelGroup>
    </PageMaxWidth>
  );
};

export const TagDivider: React.FC = () => {
  return <span className={styles.tagDivider} />;
};

export const SizedGridContent: React.FC = ({ children }) => {
  return <div className={styles.sizedGrid}>{children}</div>;
};

export default Events;
