import React, { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle } from 'react-icons/fa';
import { tournamentsClient } from 'src/apollo/client';
import { useOrgName } from 'src/apollo/local-state';
import Modal from 'src/components/modal/modal';
import {
  GetTournamentForEmailTemplate as GetEmail,
  GetTournamentForEmailTemplateVariables as GetEmailVariables,
} from 'src/graphql-types/GetTournamentForEmailTemplate';
import { TournamentFeePaymentSatus } from 'src/graphql-types/globalTournamentTypes';
import { UpdateSanction_updateTournamentSanctionStatus as UpdateSanctionResult } from 'src/graphql-types/UpdateSanction';
import * as classic from 'src/utils/classic-api';
import { retrieveUser } from 'src/utils/storage/local-storage';

import Alert from '../alert/alert';
import Spinner from '../spinner/spinner';
import * as styles from './events-approval-modal.module.less';
import { GET_TOURNAMENT_FOR_EMAIL_TEMPLATE as GET_EMAIL_TEMPLATE } from './events-approval-queries';

const MAX_MAIL_MESSAGE_LENGTH = 10000;
const CUSTOM_MESSAGE_DOM_ID = 'approval-email-custom-message';

interface ApprovalModalProps {
  submitEvents: (customMessage?: string) => Promise<UpdateSanctionResult | undefined>;
  disableShow?: boolean;
  loading?: boolean;
  buttonText?: string;
  errorMessage?: string;
  tournamentId: string;
  approved: number;
  declined: number;
  fee?: number | null;
  willAttemptCharge: boolean;
  previousChargeFailed?: boolean;
}

const EventsApprovalModal: React.FC<ApprovalModalProps> = ({
  disableShow,
  loading,
  submitEvents,
  errorMessage,
  tournamentId,
  approved,
  declined,
  buttonText,
  fee,
  willAttemptCharge,
  previousChargeFailed,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [customMailMsg, setCustomMailMsg] = useState<string>();
  const [emailTemplate, setEmailTemplate] = useState<string>();
  const [fetchingTemplate, setFetchingTemplate] = useState(true);
  const { data: emailData } = useQuery<GetEmail, GetEmailVariables>(GET_EMAIL_TEMPLATE, {
    client: tournamentsClient,
    variables: { id: tournamentId },
  });
  const orgName = useOrgName();
  const [currentChargeFailed, setCurrentChargeFailed] = useState(false);

  useEffect(() => {
    (async () => {
      if (emailData?.tournament) {
        setFetchingTemplate(true);
        const userInfo = retrieveUser();
        const e = await classic.fetchTournamentApprovalEmailTemplate(
          emailData.tournament,
          approved,
          declined,
          fee,
          userInfo,
          orgName,
        );
        setFetchingTemplate(false);
        if (e) {
          setEmailTemplate(
            e.replace(classic.TOURNAMENT_APRROVAL_TEMPLATE_FREE_TEXT, `<div id=${CUSTOM_MESSAGE_DOM_ID}></div>`),
          );
        }
      }
    })();
  }, [setEmailTemplate, emailData, orgName, approved, declined, setFetchingTemplate]);

  useEffect(() => {
    if (emailTemplate) {
      const customMessage = document.getElementById(CUSTOM_MESSAGE_DOM_ID);
      if (customMessage) {
        ReactDOM.render(<CustomMessageBox onMessageChange={setCustomMailMsg} />, customMessage);
      }
    }
  }, [emailTemplate]);

  const onVisibilityChange = useCallback(
    (show: boolean) => {
      if (!show) setCurrentChargeFailed(false);
      setShow(show);
    },
    [setShow, setCurrentChargeFailed],
  );

  return (
    <Modal
      show={show}
      onVisibilityChange={onVisibilityChange}
      title={t('tournament approval')}
      cancelButton
      openButton={{
        content: buttonText ?? t('publish'),
        props: {
          disabled: disableShow,
          spacing: { margins: { xs: 'left' } },
        },
      }}
      actionButtons={[
        {
          content: currentChargeFailed || previousChargeFailed ? t('submit pending payment') : t('send and submit'),
          props: {
            onClick: async () => {
              const updateSanctionResult = await submitEvents(customMailMsg);
              const paymentFailed =
                updateSanctionResult?.tournamentFeePayment?.status === TournamentFeePaymentSatus.CHARGE_FAILED;

              setCurrentChargeFailed(paymentFailed);
              if (!paymentFailed) setShow(false);
            },
            loading,
          },
          id: 'submit',
        },
      ]}
    >
      <div className={styles.modalContent}>
        {loading || fetchingTemplate ? (
          <Spinner />
        ) : (
          <>
            {errorMessage && (
              <div className={styles.errorText}>
                <FaExclamationTriangle className={styles.errorIcon} />
                {errorMessage}
              </div>
            )}
            {!!fee && approved > 0 && (
              <Alert spacing={{ margins: { md: 'bottom' } }} variant={currentChargeFailed ? 'warning' : 'neutral'}>
                {previousChargeFailed && !currentChargeFailed && t('previous charge failed alert')}
                {currentChargeFailed && t('current charge failed alert')}
                {(currentChargeFailed || previousChargeFailed) && (
                  <p>
                    {t('submit pending payment alert', {
                      fee: (fee / 100).toFixed(2),
                    })}
                  </p>
                )}
                {!currentChargeFailed &&
                  !previousChargeFailed &&
                  t(willAttemptCharge ? 'tournament auto charge alert' : 'tournament approval alert', {
                    fee: (fee / 100).toFixed(2),
                  })}
              </Alert>
            )}
            {emailTemplate && (
              <div className={styles.emailTemplateContainer} dangerouslySetInnerHTML={{ __html: emailTemplate }} />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

interface CustomMessageBoxProps {
  onMessageChange?: (message?: string) => void;
}

const CustomMessageBox: React.FC<CustomMessageBoxProps> = ({ onMessageChange }) => {
  const { t } = useTranslation();
  const [customMailMsg, setCustomMailMsg] = useState<string>();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCustomMailMsg(e.target.value);
      onMessageChange?.(e.target.value);
    },
    [onMessageChange, setCustomMailMsg],
  );

  return (
    <>
      <textarea
        maxLength={MAX_MAIL_MESSAGE_LENGTH}
        onChange={onChange}
        className={styles.optionalMessageBox}
        placeholder={t('optional message')}
      />
      <p>
        {t('characters remaining', {
          chars: MAX_MAIL_MESSAGE_LENGTH - (customMailMsg?.length ?? 0),
        })}
      </p>
    </>
  );
};

export default EventsApprovalModal;
