/* eslint complexity: 0 */
// TODO: Refactor this component to reduce complexity and remove the eslint complexity rule above.
import React, { useCallback, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { tournamentsClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import ApprovalButtons from 'src/components/approval-buttons/approval-buttons';
import EventsApprovalModal from 'src/components/events-approval-modal/events-approval-modal';
import Icon from 'src/components/icon/icon';
import RequestChangesModal from 'src/components/request-changes-modal/request-changes-modal';
import SanctionStatusLabel from 'src/components/sanction-status-label/sanction-status-label';
import { DrawType, EventName, Surface } from 'src/components/tournament-attributes/tournament-attributes';
import { GetEvents_tournament_events as Event, GetEvents } from 'src/graphql-types/GetEvents';
import { EventTypeEnum, TournamentFeePaymentSatus } from 'src/graphql-types/globalTournamentTypes';
import { UpdateSanction, UpdateSanctionVariables } from 'src/graphql-types/UpdateSanction';
import useApprovalToggle from 'src/hooks/use-approval-toggle';
import { useIsSanctioningBody } from 'src/utils/auth';
import getSortedEvents from 'src/utils/helper/events';

import { ExpandedMenu } from '@clubspark-react/clubspark-react-tools';

import Button from '../../button/button';
import Panel from '../../panel/panel';
import Spinner from '../../spinner/spinner';
import { UPDATE_SANCTION_STATUS } from '../events-queries';
import * as styles from '../events.module.less';

interface EventsTableProps {
  tournamentId?: string;
  data?: GetEvents;
  loading?: boolean;
}

const EventsTable: React.FC<EventsTableProps> = ({ tournamentId, data, loading }) => {
  const orgId = useOrgId();
  const isSanctioningBody = useIsSanctioningBody(tournamentId);

  const [updateSanctionStatus, { loading: updating, error }] = useMutation<UpdateSanction, UpdateSanctionVariables>(
    UPDATE_SANCTION_STATUS,
    { client: tournamentsClient },
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);

  const requiresApproval = useMemo(() => data?.tournament?.sanctionStatus === 'SUBMITTED', [data]);
  const approvalEnabled = (requiresApproval || editMode) && isSanctioningBody;

  const events = useMemo(() => getSortedEvents(data?.tournament?.events), [data?.tournament?.events]);

  const {
    updateApproval,
    publishable,
    getApprovedIds,
    approvedStates,
    approveAll,
    declineAll,
    reset,
    eventApprovedStates,
    hasChanges,
  } = useApprovalToggle(events);

  const onCancelEdit = useCallback(() => {
    setEditMode(false);
    reset();
  }, [reset, setEditMode]);

  const willAttemptCharge =
    !!data?.tournament &&
    !!data?.tournament.tournamentFeePayment?.paymentMethod?.id &&
    data?.tournament?.tournamentFeePayment?.status === TournamentFeePaymentSatus.PENDING;

  const submitEvents = useCallback(
    async (message?: string) => {
      const { approved, declined } = getApprovedIds();
      const { data } = await updateSanctionStatus({
        variables: {
          id: tournamentId,
          approved: approved.map((id) => ({ eventId: id })),
          declined: declined.map((id) => ({ eventId: id })),
          orgId,
          message,
          processPayment: willAttemptCharge,
        },
      });
      // we continue showing the dialogue if the charge failed, user may try again
      if (
        data?.updateTournamentSanctionStatus.tournamentFeePayment?.status !== TournamentFeePaymentSatus.CHARGE_FAILED
      ) {
        setEditMode(false);
      }
      return data?.updateTournamentSanctionStatus;
    },
    [getApprovedIds, updateSanctionStatus, tournamentId, orgId, willAttemptCharge],
  );

  const { approvedCount, declinedCount } = useMemo(() => {
    const { approved, declined } = getApprovedIds();
    return { approvedCount: approved.length, declinedCount: declined.length };
  }, [getApprovedIds]);

  const { t } = useTranslation();
  const teamEvents = events?.every((e) => e.division.eventType === EventTypeEnum.TEAM);
  const SCORE_FORMAT = 'score format';
  const cols: DataCols<Event> = useMemo(() => {
    const columns: DataCols<Event> = [
      {
        key: 'event',
        title: t('division'),
        getValue: (e) => <EventName event={e} />,
      },
      {
        key: 'dates',
        title: t('date(s)'),
        getValue: (e) => <EventDates event={e} />,
      },
      {
        key: 'days',
        title: t('days'),
        getValue: (e) => e.timings.lengthInDays,
      },
      {
        key: 'cost',
        title: t('cost'),
        getValue: ({ pricing: { entryFee: p } }) => `${t('currency sign')}${(p.amount / 100).toFixed(2)}`,
      },
      {
        key: 'draw type',
        title: t('draw type'),
        getValue: (e) => <DrawType event={e} />,
      },
      {
        key: SCORE_FORMAT,
        title: t(SCORE_FORMAT),
        getValue: (e) =>
          e.formatConfiguration?.scoreFormat
            ? t(`tods ${e.formatConfiguration?.scoreFormat.replace(/:/g, '-')}`)
            : t('n/a'),
      },
      {
        key: 'draw',
        title: t('entries limit'),
        getValue: (e) =>
          e.division.eventType === EventTypeEnum.TEAM ? t('n/a') : e.formatConfiguration?.drawSize ?? t('no limit'),
      },
      {
        key: 'surface',
        title: t('surface'),
        getValue: (e) => <Surface event={e} />,
      },
    ];
    if (approvalEnabled) {
      columns.push({
        key: 'buttons',
        title: t('approve or decline'),
        getValue: ({ id }) => (
          <ApprovalButtons
            id={id}
            approved={approvedStates[id]}
            disableDecline={editMode && eventApprovedStates[id]}
            updateApproval={(approved) => updateApproval(id, approved)}
          />
        ),
      });
    } else {
      columns.push({
        key: 'status',
        title: t('status'),
        getValue: (e) => <SanctionStatusLabel status={e.sanctionStatus} />,
      });
    }

    return teamEvents ? columns.filter((c) => c.key !== SCORE_FORMAT) : columns;
  }, [t, approvalEnabled, teamEvents, approvedStates, editMode, eventApprovedStates, updateApproval]);

  let errorMessage = error?.message;
  if (error?.graphQLErrors[0].message === 'sanctioningOrganisationHasNoDefaultAcccount') {
    errorMessage = t('no default account');
  }

  const handleCancel = () => {
    setShowModal(false);
  };

  const requestChanges = () => {
    setShowModal(true);
  };

  return (
    <Panel
      title={
        <span>
          {t('events')}
          <span className={styles.eventsSubtitle}>{t('events subtitle')}</span>
        </span>
      }
      headerEndContent={
        <>
          {approvalEnabled ? (
            <>
              {editMode ? (
                <Button level="secondary" onClick={onCancelEdit}>
                  {t('cancel')}
                </Button>
              ) : (
                <>
                  {requiresApproval && (
                    <RequestChangesModal
                      tournament={data?.tournament}
                      orgId={orgId}
                      showModal={showModal}
                      cancelButton={{
                        props: {
                          level: 'tertiary',
                          spacing: { margins: { sm: 'right' } },
                          onClick: handleCancel,
                        },
                        content: <>{t('cancel')}</>,
                      }}
                      onVisibilityChange={() => setShowModal(!showModal)}
                    />
                  )}
                  <ExpandedMenu
                    items={[
                      {
                        key: 'approve',
                        label: t('mark all approved'),
                        onClick: approveAll,
                      },
                      {
                        key: 'decline',
                        label: t('mark all declined'),
                        onClick: declineAll,
                      },
                      {
                        key: 'changes',
                        label: t('request changes'),
                        onClick: requestChanges,
                      },
                      { key: 'clear', label: t('clear all'), onClick: reset },
                    ]}
                    buttonText={t('approval options')}
                    buttonIcon
                    iconName="sm-down"
                    anchorElement="button"
                    buttonProps={{ disabled: !isSanctioningBody }}
                  />
                </>
              )}
              {tournamentId && (
                <EventsApprovalModal
                  buttonText={editMode ? t('update') : t('submit')}
                  loading={updating}
                  disableShow={editMode ? !hasChanges : !publishable}
                  submitEvents={submitEvents}
                  errorMessage={errorMessage}
                  tournamentId={tournamentId}
                  approved={approvedCount}
                  declined={declinedCount}
                  fee={data?.tournament.tournamentFee}
                  willAttemptCharge={willAttemptCharge}
                  previousChargeFailed={
                    data?.tournament?.tournamentFeePayment?.status === TournamentFeePaymentSatus.CHARGE_FAILED
                  }
                />
              )}
            </>
          ) : (
            !loading &&
            isSanctioningBody && (
              <Button level="secondary" onClick={() => setEditMode(true)}>
                <Icon name="sm-edit" className={styles.editIcon} />
                {t('edit approval status')}
              </Button>
            )
          )}
        </>
      }
    >
      {loading && <Spinner />}
      {data && !data.tournament && t('no tournament err')}
      {events && <AdminTable columns={cols} data={events} />}
    </Panel>
  );
};

interface EventDatesProps {
  event: Event;
}

const EventDates: React.FC<EventDatesProps> = ({ event: e }) => {
  const { t } = useTranslation();
  const { hasNonConsecutiveDays, startDate, endDate } = e.timings;
  return (
    <>
      {hasNonConsecutiveDays
        ? t('event date range', {
            dates: { start: moment(startDate), end: moment(endDate) },
          })
        : t('event start date', { date: moment(startDate) })}
      {hasNonConsecutiveDays && <div className={styles.nonConsecutiveLabel}>{t('non consecutive')}</div>}
    </>
  );
};

export default EventsTable;
